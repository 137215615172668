import { UtilService } from './util.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class GlobalServiceService {

  apiUrl = '';
  API_UPLOAD = '';
  // apiUrl = 'http://localhost:8080/sany_api/';
  // API_UPLOAD = 'http://localhost:8080/sany_api/products/upload';
  // apiUrl = 'http://localhost/sany_api/';
  // API_UPLOAD = 'http://localhost/sany_api/products/upload';
  public MODE_STATE = '';

/*
  private sale_section = [
    
    {
      title: 'ลูกค้า',
      //   url: 'cus-list',
      img: '../assets/img/sales/customer.svg',
      icon: 'paper-plane',
      children: [
        {
          title: 'ลูกค้า',
          url: 'cus-list',
          img: '../assets/img/customer.svg',
          icon: 'paper-plane'
        },
        {
          title: 'ประเภทธุรกิจ',
          url: 'customer-type',
          img: '../assets/img/customer.svg',
          icon: 'paper-plane'
        },

        {
          title: 'เกรดลูกค้า',
          url: 'customer-grade',
          img: '../assets/img/customer.svg',
          icon: 'paper-plane'
        },
        {
          title: 'จัดการรายชื่อลูกค้ากับเซลล',
          url: 'employee-customer-list',
          img: '../assets/img/customer.svg',
          icon: 'paper-plane'
        }
      ]
    },
    {
      title: 'แผนเยี่ยมลูกค้า',
      url: 'angenda-list',
      img: '../assets/img/sales/meet.svg',
      icon: 'paper-plane'
    },
    {
      title: 'เสนอราคา',
      img: '../assets/img/sales/quotation.svg',
      url: 'sale-quotation',
      icon: 'document-text',
    },
    {
      title: 'ขออนุมัติสินเชื่อ',
      img: '../assets/img/sales/saleorder-finance.png',
      url: 'saleorder-finance',
      icon: 'document-text',
    },
    {
      title: 'รายการสั่งขาย',
      img: '../assets/img/sales/sale-order.svg',
      url: 'sale-order',
      icon: 'document-text',
    },
    {
      title: 'จัดเตรียมสินค้า',
      img: '../assets/img/sales/joborder.svg',
      url: 'job-order',
      icon: 'document-text',
    },
    {
      title: 'รายการพร้อมส่ง',
      img: '../assets/img/sales/order-complete.svg',
      url: 'order-complete',
      icon: 'document-text',
    },
    {
      title: 'รายการปล่อยรถ',
      img: '../assets/img/sales/order-complete.svg',
      url: 'ship-order',
      icon: 'document-text',
    },

  ];

  private product_section = [

    {
      title: 'สินค้า',
      url: 'product-main',
      img: '../assets/img/product.svg',
      icon: 'paper-plane'
    },
    {
      title: 'ประเภทสินค้า',
      url: 'categories',
      img: '../assets/img/product.svg',
      icon: 'paper-plane'
    },
    {
      title: 'โปรโมชั่น',
      url: 'promotion',
      img: '../assets/img/product.svg',
      icon: 'paper-plane'
    },

  ];

  private user_section = [
    {
      title: 'ผู้ใช้งาน',
      url: 'user-main',
      img: '../assets/img/team.svg',
      icon: 'warning'
    },
    {
      title: 'สิทธิผู้ใช้งาน',
      url: 'user-role-main',
      img: '../assets/img/team.svg',
      icon: 'warning'
    },
    {
      title: 'พนักงาน',
      url: 'employees-list',
      img: '../assets/img/team.svg',
      icon: 'warning'
    },
    {
      title: 'Leasing',
      // url: '/folder/Inbox',
      img: '../assets/img/sales.svg',
      icon: 'mail',
      children: [
        {
          title: 'พนักงาน Leasing',
          url: 'leasing-main',
          img: '../assets/img/team.svg',
          icon: 'warning'
        },
        {
          title: 'บริษัท Leasing',
          url: 'company-leasing',
          img: '../assets/img/team.svg',
          icon: 'warning'
        }
      ]
    }
    ,


    {
      title: 'ตั้งค่า',
      // url: '/folder/Inbox',
      img: '../assets/img/file_config.svg',
      icon: 'mail',
      children: [
      ]
    },
  ];
*/
  public appPages = [];
  public count1=0;
  public count2=0;
  public count3=0;
  //private sale_section=[];
  user:any;

  constructor(
    private http: HttpClient,
    private util: UtilService
  ) {
    this.apiUrl = this.util.API_URL;
    this.API_UPLOAD = this.util.API_UPLOAD;
    // if (history.state != null || history.state != undefined ) {
    //   console.log('have state');
    //   console.log(history.state);
    //   if(history.state.user != undefined){
    //     var getuser=history.state.user;
    //     this.user = JSON.parse(localStorage.user);
    //     console.log(this.user);
    //     // this.pg=JSON.parse(getuser.pg);
    //     //this.util.PG = JSON.parse(getuser.pg);
    //   }else{
    //     if (localStorage.getItem("pg") !== null) {
    //       console.log('have storate');
    //       // this.pg=JSON.parse(localStorage.getItem('pg'));
    //       //this.util.PG = JSON.parse(localStorage.getItem('pg'));
    //       this.user = JSON.parse(localStorage.user);
    //       //console.log(this.util.PG);
    //     }
    //   }
    // }
    // else if (localStorage.getItem("pg") !== null && localStorage.getItem("pg") !==undefined)  {
    //     console.log('have storate');
    //     //this.pg=JSON.parse(localStorage.getItem('pg'));
    //     //this.util.PG = JSON.parse(localStorage.getItem('pg'));
    //     console.log(this.util.PG)
    //     this.user = JSON.parse(localStorage.user);
    //     //console.log('menu1 ');
    //     //console.log(this.pg[1]);
    // }else{
    //   this.util.getObservable().subscribe((data) => {
    //     console.log('Data received', data);
    //     this.util.PG = data;
    //     //this.user=this.user;
    //   });
    // }
    
    // console.log('pg');
    // console.log(this.util.PG);
    // if(this.util.PG) {
    //   //this.sale_section = this.util.PG[1];
    //   //this.product_section = this.util.PG[2];
    //   //this.user_section = this.util.PG[3];
    //   //global.appPages[0]= this.util.PG[1];

    //   if(this.util.PG[1].length==0){
    //     this.count1=0;
    //   }else{
    //     this.count1=1;
    //   }
    //   if(this.util.PG[2].length==0){
    //     this.count2=0;
    //   }else{
    //     this.count2=1;
    //   }
    //   if(this.util.PG[3].length==0){
    //     this.count3=0;
    //   }else{
    //     this.count3=1;
    //   }
    //   console.log('user component');
    //   console.log(this.user);
    // }
    

   }

  setState(data: any) {
    this.MODE_STATE = data;
  }

  setMenu(data: any) {
    console.log(data);
    console.log(this.util.PG);
    localStorage.setItem("mIndex",data);
    this.util.start_selectedIndex = 0;
    this.util.start_subIndex = 0;
    
    // this.appPages = this.util.PG[data].sort();
    // switch (data) {
    //   case '1': this.appPages = this.util.PG[data]; //this.sale_section; console.log(this.appPages); break;
    //   case '2': this.appPages = this.product_section; break;
    //   case '3': this.appPages = this.user_section; break;
    // }
  }
  login(value: any) {
    // console.log(value)
    const dataForm = this.getFormData(value);
    console.log("Value +++",value)
    return this.http.post(
      this.apiUrl + 'users/login',
      dataForm,
      {}
    );
  }
  getFormData(object) {
    const formData = new FormData();
    Object.keys(object).forEach(key => formData.append(key, object[key]));
    return formData;
  }

  /* cus grade */
  cus_grade_add(value: any) {
    console.log(value)
    const dataForm = this.getFormData(value);
    return this.http.post(
      this.apiUrl + 'CustomerGrade/AddCustomerGrade',
      dataForm,
      {}
    );
  }
  cus_grade_update(value: any) {
    console.log(value)
    const dataForm = this.getFormData(value);
    return this.http.post(
      this.apiUrl + 'CustomerGrade/UpdateCustomerGrade',
      dataForm,
      {}
    );
  }
  cus_grade_delete(value: any) {
    console.log(value)
    const dataForm = this.getFormData(value);
    return this.http.post(
      this.apiUrl + 'CustomerGrade/DelCustomerGrade',
      dataForm,
      {}
    );
  }
  cus_grade_list(user_id: any, pageNumber: any, pageSize: any, filter: any) {
    // const dataForm = this.getFormData(value);
    const formData = new FormData();
    formData.append('pageNumber', pageNumber);
    formData.append('pageSize', pageSize);
    formData.append('filter', JSON.stringify(filter));
    return this.http.post(
      this.apiUrl + 'CustomerGrade/getCustomerGrade/',
      formData,
      {}
    );
  }
  /* cus grade */


  /* cus type */
  cus_type_add(value: any) {
    console.log(value)
    const dataForm = this.getFormData(value);
    return this.http.post(
      this.apiUrl + 'CustomerType/AddCustomerType',
      dataForm,
      {}
    );
  }
  cus_type_update(value: any) {
    console.log(value)
    const dataForm = this.getFormData(value);
    return this.http.post(
      this.apiUrl + 'CustomerType/UpdateCustomerType',
      dataForm,
      {}
    );
  }
  cus_type_delete(value: any) {
    console.log(value)
    const dataForm = this.getFormData(value);
    return this.http.post(
      this.apiUrl + 'CustomerType/DelCustomerType',
      dataForm,
      {}
    );
  }
  cus_type_list(user_id: any, pageNumber: any, pageSize: any, filter: any) {
    // const dataForm = this.getFormData(value);
    const formData = new FormData();
    formData.append('pageNumber', pageNumber);
    formData.append('pageSize', pageSize);
    formData.append('filter', JSON.stringify(filter));
    return this.http.post(
      this.apiUrl + 'CustomerType/getCustomerType/',
      formData,
      {}
    );
  }
  /* cus type */

  /* categories */
  categories_add(value: any) {
    console.log(value)
    //const dataForm = this.getFormData(value);
    return this.http.post(
      this.apiUrl + 'Categories/AddCategories',
      value,
      {}
    );
  }
  categories_update(value: any) {
    console.log(value)
    //const dataForm = this.getFormData(value);
    return this.http.post(
      this.apiUrl + 'Categories/UpdateCategories',
      value,
      {}
    );
  }
  categories_delete(value: any) {
    console.log(value)
    const dataForm = this.getFormData(value);
    return this.http.post(
      this.apiUrl + 'Categories/DelCategories',
      dataForm,
      {}
    );
  }
  categories_list() {
    return this.http.post(
      this.apiUrl + 'Categories/getCategories',
      {}
    );
  }
  /* categories*/
  //====== product promotion =========
  getPromotion() {
    return this.http.post(
      this.apiUrl + 'Promotions/getPromotion',
      '',
      {}
    );
  }

  getProductList() {
    return this.http.post(
      this.apiUrl + 'Promotions/getPrdList',
      '',
      {}
    );
  }

  savePromotion(value: any) {
    console.log('save');
    const dataForm = this.getFormData(value);
    return this.http.post(
      this.apiUrl + 'Promotions/addPromotion',
      dataForm,
      {}
    );
  }

  updatePromotion(value: any) {
    console.log('edit');
    const dataForm = this.getFormData(value);
    return this.http.post(
      this.apiUrl + 'Promotions/updatePromotion',
      dataForm,
      {}
    );
  }

  delPromotion(value: any) {
    console.log('delete');
    const dataForm = this.getFormData(value);
    return this.http.post(
      this.apiUrl + 'Promotions/delPromotion',
      dataForm,
      {}
    );
  }

  //================ Inquiry ==================

  getDocNum() {
    return this.http.post(
      this.apiUrl + 'Inquiry/getRunning',
      '',
      {}
    );
  }

  getCusSelectList() {
    return this.http.post(
      this.apiUrl + 'Inquiry/getCustomerList',
      '',
      {}
    );
  }

  getCusAddress(value: any) {
    //const dataForm = this.getFormData(value);
    console.log('id = '+value);
    const formData = new FormData();
    formData.append('id', value);
    return this.http.post(
      this.apiUrl + 'Customer/getAddress',
      formData,
      {}
    );
  }

  getCusContact(value: any) {
    //const dataForm = this.getFormData(value);
    console.log('id = '+value);
    const formData = new FormData();
    formData.append('id', value);
    return this.http.post(
      this.apiUrl + 'Customer/getContactDropDown',
      formData,
      {}
    );
  }

  getPromoList(value: any) {
    //const dataForm = this.getFormData(value);
    return this.http.post(
      this.apiUrl + 'Promotions/getPromoDropDown',
      value,
      {}
    );
  }

  getPrdPrice(value: any) {
    //const dataForm = this.getFormData(value);
    return this.http.post(
      this.apiUrl + 'Products/getPrdByID',
      value,
      {}
    );
  }

  getPromoInfo(value: any) {
    //const dataForm = this.getFormData(value);
    return this.http.post(
      this.apiUrl + 'Promotions/getPromoByID',
      value,
      {}
    );
  }
  //====== USER ROLE ======
  getUserRole(user_id: any, pageNumber: any, pageSize: any, filter: any) {
    // const dataForm = this.getFormData(value);
    const formData = new FormData();
    formData.append('pageNumber', pageNumber);
    formData.append('pageSize', pageSize);
    formData.append('filter', JSON.stringify(filter));
    return this.http.post(
      this.apiUrl + 'UserRole/getUserRole/',
      formData,
      {}
    );
  }

  AddUserRole(value: any) {
    console.log(value)
    const dataForm = this.getFormData(value);
    return this.http.post(
      this.apiUrl + 'UserRole/AddUserRole',
      dataForm,
      {}
    );
  }
  changepassword(user_id: any,value: any) {
    // const dataForm = this.getFormData(value);
    const dataForm = this.getFormData(value);
    dataForm.append('user_id', user_id);
    return this.http.post(
      this.apiUrl + 'Users/changepassword/',
      dataForm,
      {}
    );
  }
  updateUserRole(value: any) {
    console.log(value)
    const dataForm = this.getFormData(value);
    return this.http.post(
      this.apiUrl + 'UserRole/UpdateUserRole',
      dataForm,
      {}
    );
  }
  delUserRole(value: any) {
    const dataForm = this.getFormData(value);
    return this.http.post(
      this.apiUrl + 'UserRole/delUserRole',
      dataForm,
      {}
    );
  }

  getModules() {
    return this.http.post(
      this.apiUrl + 'UserRole/getModules',
      '',
      {}
    );
  }
  getPrograms(value: any) {
    if (value == null || value == '') {
      value = null;
    }
    return this.http.post(
      this.apiUrl + 'UserRole/getPrograms/' + value,
      '',
      {}
    );
  }
  //========================
  //========USER===========

  getUserInfoById(emp_id: any){
    const formData = new FormData();
    formData.append('emp_id', emp_id);
    return this.http.post(
      this.apiUrl + 'Users/getUserInfoByEmpId',
      formData,
      {}
    );
  }
  
  getUser(user_id: any, pageNumber: any, pageSize: any, filter: any) {
    const formData = new FormData();
    formData.append('userid', user_id);
    formData.append('pageNumber', pageNumber);
    formData.append('pageSize', pageSize);
    formData.append('filter', JSON.stringify(filter));
    return this.http.post(
      this.apiUrl + 'Users/getUsers',
      formData,
      {}
    );
  }
  AddUser(value: any) {
    console.log(value)
    const dataForm = this.getFormData(value);
    return this.http.post(
      this.apiUrl + 'Users/AddUsers',
      dataForm,
      {}
    );
  }
  updateUser(value: any) {
    console.log(value)
    const dataForm = this.getFormData(value);
    return this.http.post(
      this.apiUrl + 'Users/UpdateUsers',
      dataForm,
      {}
    );
  }
  delUser(value: any) {
    const dataForm = this.getFormData(value);
    return this.http.post(
      this.apiUrl + 'Users/DelUsers',
      dataForm,
      {}
    );
  }
  lookupRole() {
    return this.http.post(
      this.apiUrl + 'Users/lookupRole',
      '',
      {}
    );
  }
  lookupUserType() {
    return this.http.post(
      this.apiUrl + 'Users/lookupType',
      '',
      {}
    );
  }
  lookupEmployee() {
    return this.http.post(
      this.apiUrl + 'users/lookupEmployee',
      '',
      {}
    );
  }
  //=======================

  /* leasing */
  leasing_add(value: any) {
    console.log(value)
    const dataForm = this.getFormData(value);
    return this.http.post(
      this.apiUrl + 'leasing/AddLeasing',
      dataForm,
      {}
    );
  }
  leasing_update(value: any) {
    console.log(value)
    const dataForm = this.getFormData(value);
    return this.http.post(
      this.apiUrl + 'leasing/UpdateLeasing',
      dataForm,
      {}
    );
  }
  leasing_delete(value: any) {
    console.log(value)
    const dataForm = this.getFormData(value);
    return this.http.post(
      this.apiUrl + 'leasing/DelLeasing',
      dataForm,
      {}
    );
  }
  leasing_list() {
    return this.http.post(
      this.apiUrl + 'leasing/getLeasing',
      {}
    );
  }
  lookupCompanyLeasing() {
    return this.http.post(
      this.apiUrl + 'leasing/lookupCompanyLeasing',
      '',
      {}
    );
  }
  /* leasing*/

  //=======================

  /* company_leasing */
  company_leasing_add(value: any) {
    console.log(value)
    const dataForm = this.getFormData(value);
    return this.http.post(
      this.apiUrl + 'CompanyLeasing/AddCompanyLeasing',
      dataForm,
      {}
    );
  }
  company_leasing_update(value: any) {
    console.log(value)
    const dataForm = this.getFormData(value);
    return this.http.post(
      this.apiUrl + 'CompanyLeasing/UpdateCompanyLeasing',
      dataForm,
      {}
    );
  }
  company_leasing_delete(value: any) {
    console.log(value)
    const dataForm = this.getFormData(value);
    return this.http.post(
      this.apiUrl + 'CompanyLeasing/DelCompanyLeasing',
      dataForm,
      {}
    );
  }
  company_leasing_list() {
    return this.http.post(
      this.apiUrl + 'CompanyLeasing/getCompanyLeasing',
      {}
    );
  }
  /* CompanyLeasing*/

  /* customer */
  customer_add(value: any) {
    console.log(value)
    const dataForm = this.getFormData(value);
    return this.http.post(
      this.apiUrl + 'Customer/AddCustomer',
      dataForm,
      {}
    );
  }
  customer_update(value: any) {
    console.log(value)
    const dataForm = this.getFormData(value);
    return this.http.post(
      this.apiUrl + 'Customer/UpdateCustomer',
      dataForm,
      {}
    );
  }
  customer_delete(value: any) {
    console.log(value)
    const dataForm = this.getFormData(value);
    return this.http.post(
      this.apiUrl + 'Customer/DelCustomer',
      dataForm,
      {}
    );
  }
  customer_list(user_id: any, pageNumber: any, pageSize: any, filter: any) {
    // const dataForm = this.getFormData(value);
    const formData = new FormData();
    formData.append('pageNumber', pageNumber);
    formData.append('pageSize', pageSize);
    formData.append('filter', JSON.stringify(filter));
    return this.http.post(
      this.apiUrl + 'Customer/getCustomer/' + user_id,
      formData,
      {}
    );
  }
  customer_con_list(value: any) {
    return this.http.post(
      this.apiUrl + 'Customer/getCusContract/' + value,
      {}
    );
  }
  province_ddl() {
    return this.http.post(
      this.apiUrl + 'Customer/lookupProvinces/',
      {}
    );
  }
  amphures_ddl(value: any) {
    return this.http.post(
      this.apiUrl + 'Customer/lookupAmphures/' + value,
      {}
    );
  }
  districts_ddl(value: any) {
    return this.http.post(
      this.apiUrl + 'Customer/lookupDistricts/' + value,
      {}
    );
  }
  lookupCustype() {
    return this.http.post(
      this.apiUrl + 'Customer/lookupCusType',
      '',
      {}
    );
  }
  lookupCusGrade() {
    return this.http.post(
      this.apiUrl + 'Customer/lookupCusGrade',
      '',
      {}
    );
  }
  lookupSourceCustomer() {
    return this.http.post(
      this.apiUrl + 'Customer/lookupSourceCustomer',
      '',
      {}
    );
  }
  /* Customer*/

  /* Products */
  product_add(value: any) {
    console.log(value)
    const dataForm = this.getFormData(value);

    return this.http.post(
      this.apiUrl + 'Products/AddProducts',
      dataForm,
      {}
    );
  }
  product_update(value: any) {
    console.log(value)
    const dataForm = this.getFormData(value);
    return this.http.post(
      this.apiUrl + 'Products/UpdateProducts',
      dataForm,
      {}
    );
  }
  product_delete(value: any) {
    console.log(value)
    const dataForm = this.getFormData(value);
    return this.http.post(
      this.apiUrl + 'Products/DelProducts',
      dataForm,
      {}
    );
  }
  product_list(user_id: any, pageNumber: any, pageSize: any, filter: any) {
    // const dataForm = this.getFormData(value);
    const formData = new FormData();
    formData.append('pageNumber', pageNumber);
    formData.append('pageSize', pageSize);
    formData.append('filter', JSON.stringify(filter));
    return this.http.post(
      this.apiUrl + 'Products/getProducts/',
      formData,
      {}
    );
  }
  product_serial_list(value: any) {
    return this.http.post(
      this.apiUrl + 'Products/getSerial/' + value,
      {}
    );
  }
  lookupCategories() {
    return this.http.post(
      this.apiUrl + 'Products/lookupCategories',
      '',
      {}
    );
  }
  /* Products*/
  /*Employee*/
  emp_add(value: any) {
    console.log(value)
    const dataForm = this.getFormData(value);
    return this.http.post(
      this.apiUrl + 'Employee/AddEmployee',
      dataForm,
      {}
    );
  }
  emp_update(value: any) {
    console.log(value)
    const dataForm = this.getFormData(value);
    return this.http.post(
      this.apiUrl + 'Employee/UpdateEmployee',
      dataForm,
      {}
    );
  }
  emp_delete(value: any) {
    console.log(value)
    const dataForm = this.getFormData(value);
    return this.http.post(
      this.apiUrl + 'Employee/DelEmployee',
      dataForm,
      {}
    );
  }
  emp_list(user_id: any, pageNumber: any, pageSize: any, filter: any) {
    // const dataForm = this.getFormData(value);
    const formData = new FormData();
    formData.append('pageNumber', pageNumber);
    formData.append('pageSize', pageSize);
    formData.append('filter', JSON.stringify(filter));
    return this.http.post(
      this.apiUrl + 'Employee/getEmployee/',
      formData,
      {}
    );
  }

  lookupPosition() {
    return this.http.post(
      this.apiUrl + 'Employee/lookupPosition',
      '',
      {}
    );
  }
  lookupDvs() {
    return this.http.post(
      this.apiUrl + 'Employee/lookupDvs',
      '',
      {}
    );
  }
  lookupBranch() {
    return this.http.post(
      this.apiUrl + 'Employee/lookupBranch',
      '',
      {}
    );
  }
  emp_position_list(value: any) {
    return this.http.post(
      this.apiUrl + 'Employee/getEmpPostion/' + value,
      {}
    );
  }
  
  lookupHeadEmp() {
    return this.http.post(
      this.apiUrl + 'Employee/lookupHeadEmp',
      '',
      {}
    );
  }
  /* emp */

  /*branch*/
  branch_add(value: any) {
    console.log(value)
    const dataForm = this.getFormData(value);
    return this.http.post(
      this.apiUrl + 'Branch/AddBranch',
      dataForm,
      {}
    );
  }
  branch_update(value: any) {
    console.log(value)
    const dataForm = this.getFormData(value);
    return this.http.post(
      this.apiUrl + 'Branch/UpdateBranch',
      dataForm,
      {}
    );
  }
  branch_delete(value: any) {
    console.log(value)
    const dataForm = this.getFormData(value);
    return this.http.post(
      this.apiUrl + 'Branch/DelBranch',
      dataForm,
      {}
    );
  }
  branch_list() {
    return this.http.post(
      this.apiUrl + 'Branch/getBranch',
      {}
    );
  }
  /* branch */

  /*Department*/
  department_add(value: any) {
    console.log(value)
    const dataForm = this.getFormData(value);
    return this.http.post(
      this.apiUrl + 'Department/AddDepartment',
      dataForm,
      {}
    );
  }
  department_update(value: any) {
    console.log(value)
    const dataForm = this.getFormData(value);
    return this.http.post(
      this.apiUrl + 'Department/UpdateDepartment',
      dataForm,
      {}
    );
  }
  department_delete(value: any) {
    console.log(value)
    const dataForm = this.getFormData(value);
    return this.http.post(
      this.apiUrl + 'Department/DelDepartment',
      dataForm,
      {}
    );
  }
  department_list() {
    return this.http.post(
      this.apiUrl + 'Department/getDepartment',
      {}
    );
  }
  /* Department */

  /*Division*/
  division_add(value: any) {
    console.log(value)
    const dataForm = this.getFormData(value);
    return this.http.post(
      this.apiUrl + 'Division/AddDivision',
      dataForm,
      {}
    );
  }
  division_update(value: any) {
    console.log(value)
    const dataForm = this.getFormData(value);
    return this.http.post(
      this.apiUrl + 'Division/UpdateDivision',
      dataForm,
      {}
    );
  }
  division_delete(value: any) {
    console.log(value)
    const dataForm = this.getFormData(value);
    return this.http.post(
      this.apiUrl + 'Division/DelDivision',
      dataForm,
      {}
    );
    
  }
  lookupDepartment() {
    return this.http.post(
      this.apiUrl + 'Division/lookupDepartment',
      {}
    );
    
  }
  division_list() {
    return this.http.post(
      this.apiUrl + 'Division/getDivision',
      {}
    );
  }
  /* Division */

  /*Position*/
  position_add(value: any) {
    console.log(value)
    const dataForm = this.getFormData(value);
    return this.http.post(
      this.apiUrl + 'Position/AddPosition',
      dataForm,
      {}
    );
  }
  position_update(value: any) {
    console.log(value)
    const dataForm = this.getFormData(value);
    return this.http.post(
      this.apiUrl + 'Position/UpdatePosition',
      dataForm,
      {}
    );
  }
  position_delete(value: any) {
    console.log(value)
    const dataForm = this.getFormData(value);
    return this.http.post(
      this.apiUrl + 'Position/DelPosition',
      dataForm,
      {}
    );
  }
  position_list() {
    return this.http.post(
      this.apiUrl + 'Position/getPosition',
      {}
    );
  }
  /* Position */
  /* EMPCUS */
   emp_customer_list(user_id: any) {
    return this.http.post(
      this.apiUrl + 'EmployeeCustomer/getEmpCustomer/'+ user_id,
      {}
    );
  }
  emp_customer_add(value: any) {
    console.log(value)
    const dataForm = this.getFormData(value);
    return this.http.post(
      this.apiUrl + 'EmployeeCustomer/AddCustomer',
      dataForm,
      {}
    );
  }
  emp_customer_update(value: any) {
    console.log(value)
    const dataForm = this.getFormData(value);
    return this.http.post(
      this.apiUrl + 'EmployeeCustomer/UpdateCustomer',
      dataForm,
      {}
    );
  }
  emp_customer_delete(value: any) {
    console.log(value)
    const dataForm = this.getFormData(value);
    return this.http.post(
      this.apiUrl + 'EmployeeCustomer/DelCustomer',
      dataForm,
      {}
    );
  }
  emp_customer_emp_list(value: any) {
    return this.http.post(
      this.apiUrl + 'EmployeeCustomer/lookupEmployee/'+value,
      {}
    );
  }
  lookupCustomerById(value:any) {
    return this.http.post(
      this.apiUrl + 'EmployeeCustomer/lookupCustomerById/'+value,
      {}
    );
  }
  emp_customer_cus_list() {
    return this.http.post(
      this.apiUrl + 'EmployeeCustomer/lookupCustomer',
      {}
    );
  }

  check_serial(value:any){
    const formData = new FormData();
    formData.append('serial', value);
    //formData.append('pageSize', pageSize);
    //formData.append('filter', JSON.stringify(filter));
    return this.http.post(
      this.apiUrl + 'Products/checkSerial/',
      formData,
      {}
    );
  }
  /* EMPCUS*/

  // tslint:disable-next-line: jsdoc-format
  /**   Master Status ***/
  lookupMasterStatus() {
    return this.http.post(
      this.apiUrl + 'inquiry/getMasterStatus',
      '',
      {}
    );
  }
  lookupCustomerStatus() {
    return this.http.post(
      this.apiUrl + 'Customer/lookupCustomerStatus',
      '',
      {}
    );
  }
  lookupCustomerGroup() {
    return this.http.post(
      this.apiUrl + 'Customer/lookupCustomerGroup',
      '',
      {}
    );
  }

  getUserLog(user_id: any, pageNumber: any, pageSize: any, filter: any) {
    // const dataForm = this.getFormData(value);
    const formData = new FormData();
    formData.append('pageNumber', pageNumber);
    formData.append('pageSize', pageSize);
    formData.append('filter', JSON.stringify(filter));
    return this.http.post(
      this.apiUrl + 'Users/getUserLog/',
      formData,
      {}
    );
  }
}
