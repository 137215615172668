import { SelectivePreloadingStrategyService } from './service/auth/preload.service';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes, CanActivate } from '@angular/router';
import {
  AuthGuardService as AuthGuard
} from './service/auth/auth-guard.service';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'folder/:id',
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  },
  {
    path: 'main-control',
    loadChildren: () => import('./hub/main-control/main-control.module').then( m => m.MainControlPageModule),
    canActivate: [AuthGuard],
    data: { preload: true }
  },
  {
    path: 'login',
    loadChildren: () => import('./users/login/login.module').then( m => m.LoginPageModule),
    data: { preload: true }
  },
  {
    path: 'sale-main',
    loadChildren: () => import('./sales/main/main.module').then( m => m.MainPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'promotion',
    loadChildren: () => import('./promotions/promotion/promotion.module').then( m => m.PromotionPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'cus-list',
    loadChildren: () => import('./customer/cus-list/cus-list.module').then( m => m.CusListPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'sale-quotation',
    loadChildren: () => import('./sales/sale-quotation/sale-quotation.module').then( m => m.SaleQuotationPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'cus-detail',
    loadChildren: () => import('./customer/cus-detail/cus-detail.module').then( m => m.CusDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'cus-contract-detail',
    loadChildren: () => import('./customer/cus-contract-detail/cus-contract-detail.module').then( m => m.CusContractDetailPageModule)
  },
  {
    path: 'branch',
    loadChildren: () => import('./setting/branch/branch.module').then( m => m.BranchPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'branch-detail',
    loadChildren: () => import('./setting/branch-detail/branch-detail.module').then( m => m.BranchDetailPageModule)
  },
  {
    path: 'position',
    loadChildren: () => import('./setting/position/position.module').then( m => m.PositionPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'position-detail',
    loadChildren: () => import('./setting/position-detail/position-detail.module').then( m => m.PositionDetailPageModule)
  },
  {
    path: 'department',
    loadChildren: () => import('./setting/department/department.module').then( m => m.DepartmentPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'department-detail',
    loadChildren: () => import('./setting/department-detail/department-detail.module').then( m => m.DepartmentDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'division',
    loadChildren: () => import('./setting/division/division.module').then( m => m.DivisionPageModule)
  },
  {
    path: 'division-detail',
    loadChildren: () => import('./setting/division-detail/division-detail.module').then( m => m.DivisionDetailPageModule)
  },
  {
    path: 'product-main',
    loadChildren: () => import('./products/product-main/product-main.module').then( m => m.ProductMainPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'user-main',
    loadChildren: () => import('./users/user-main/user-main.module').then( m => m.UserMainPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'categories',
    loadChildren: () => import('./products/categories/categories.module').then( m => m.CategoriesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'categories-detail',
    loadChildren: () => import('./products/categories-detail/categories-detail.module').then( m => m.CategoriesDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'product-detail',
    loadChildren: () => import('./products/product-detail/product-detail.module').then( m => m.ProductDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'user-detail',
    loadChildren: () => import('./users/user-detail/user-detail.module').then( m => m.UserDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'user-role-main',
    loadChildren: () => import('./users/user-role-main/user-role-main.module').then( m => m.UserRoleMainPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'user-role-detail',
    loadChildren: () => import('./users/user-role-detail/user-role-detail.module').then( m => m.UserRoleDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'leasing-main',
    loadChildren: () => import('./leasing/leasing-main/leasing-main.module').then( m => m.LeasingMainPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'leasing-detail',
    loadChildren: () => import('./leasing/leasing-detail/leasing-detail.module').then( m => m.LeasingDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'company-leasing',
    loadChildren: () => import('./leasing/company-leasing/company-leasing.module').then( m => m.CompanyLeasingPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'company-leasing-detail',
    loadChildren: () => import('./leasing/company-leasing-detail/company-leasing-detail.module').then( m => m.CompanyLeasingDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'customer-main',
    loadChildren: () => import('./customer/customer-main/customer-main.module').then( m => m.CustomerMainPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'customer-type',
    loadChildren: () => import('./customer/customer-type/customer-type.module').then( m => m.CustomerTypePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'customer-type-detail',
    loadChildren: () => import('./customer/customer-type-detail/customer-type-detail.module').then( m => m.CustomerTypeDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'customer-grade',
    loadChildren: () => import('./customer/customer-grade/customer-grade.module').then( m => m.CustomerGradePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'customer-grade-detail',
    loadChildren: () => import('./customer/customer-grade-detail/customer-grade-detail.module').then( m => m.CustomerGradeDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'promotion-detail',
    loadChildren: () => import('./promotions/promotion-detail/promotion-detail.module').then( m => m.PromotionDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'sale-detail',
    loadChildren: () => import('./sales/sale-detail/sale-detail.module').then( m => m.SaleDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'sale-product',
    loadChildren: () => import('./sales/sale-product/sale-product.module').then( m => m.SaleProductPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'customer-sign',
    loadChildren: () => import('./sales/customer-sign/customer-sign.module').then( m => m.CustomerSignPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'view-quotation',
    loadChildren: () => import('./sales/view-quotation/view-quotation.module').then( m => m.ViewQuotationPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'product-serial',
    loadChildren: () => import('./products/product-serial/product-serial.module').then( m => m.ProductSerialPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'employees-list',
    loadChildren: () => import('./employees/employees-list/employees-list.module').then( m => m.EmployeesListPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'employees-detail',
    loadChildren: () => import('./employees/employees-detail/employees-detail.module').then( m => m.EmployeesDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'sale-order',
    loadChildren: () => import('./sales/sale-order/sale-order.module').then( m => m.SaleOrderPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'sale-order-detail',
    loadChildren: () => import('./sales/sale-order-detail/sale-order-detail.module').then( m => m.SaleOrderDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'job-order-detail',
    loadChildren: () => import('./jobs/job-order-detail/job-order-detail.module').then( m => m.JobOrderDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'job-order',
    loadChildren: () => import('./jobs/job-order/job-order.module').then( m => m.JobOrderPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'saleorder-finance',
    loadChildren: () => import('./sales/saleorder-finance/saleorder-finance.module').then( m => m.SaleorderFinancePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'view-order-finance',
    loadChildren: () => import('./sales/view-order-finance/view-order-finance.module').then( m => m.ViewOrderFinancePageModule)
  },
  {
    path: 'employee-position',
    loadChildren: () => import('./employees/employee-position/employee-position.module').then( m => m.EmployeePositionPageModule)
  },
  {
    path: 'free-product',
    loadChildren: () => import('./sales/free-product/free-product.module').then( m => m.FreeProductPageModule)
  },
  {
    path: 'sale-quotation-print',
    loadChildren: () => import('./sales/sale-quotation-print/sale-quotation-print.module').then( m => m.SaleQuotationPrintPageModule)
  },
  {
    path: 'order-complete',
    loadChildren: () => import('./jobs/order-complete/order-complete.module').then( m => m.OrderCompletePageModule)
  },
  {
    path: 'employee-customer-list',
    loadChildren: () => import('./customer/employee-customer-list/employee-customer-list.module').then( m => m.EmployeeCustomerListPageModule)
  },
  {
    path: 'employee-customer-detail',
    loadChildren: () => import('./customer/employee-customer-detail/employee-customer-detail.module').then( m => m.EmployeeCustomerDetailPageModule)
  },
  {
    path: 'angenda-list',
    loadChildren: () => import('./agenda/angenda-list/angenda-list.module').then( m => m.AngendaListPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'job-detail',
    loadChildren: () => import('./jobs/job-detail/job-detail.module').then( m => m.JobDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'ship-order',
    loadChildren: () => import('./delivery/ship-order/ship-order.module').then( m => m.ShipOrderPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'ship-detail',
    loadChildren: () => import('./delivery/ship-detail/ship-detail.module').then( m => m.ShipDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'changepassword',
    loadChildren: () => import('./users/changepassword/changepassword.module').then( m => m.ChangepasswordPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'angenda-add',
    loadChildren: () => import('./agenda/angenda-add/angenda-add.module').then( m => m.AngendaAddPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'angenda-edit',
    loadChildren: () => import('./agenda/angenda-edit/angenda-edit.module').then( m => m.AngendaEditPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'approve-sale',
    loadChildren: () => import('./approve/approve-sale/approve-sale.module').then( m => m.ApproveSalePageModule)
  },
  {
    path: 'approve-order',
    loadChildren: () => import('./approve/approve-order/approve-order.module').then( m => m.ApproveOrderPageModule)
  },
  {
    path: 'agenda-report',
    loadChildren: () => import('./report/agenda-report/agenda-report.module').then( m => m.AgendaReportPageModule)
  },
  {
    path: 'sale-report-sum',
    loadChildren: () => import('./report/sale-report-sum/sale-report-sum.module').then( m => m.SaleReportSumPageModule)
  },
  {
    path: 'sale-report-order',
    loadChildren: () => import('./report/sale-report-order/sale-report-order.module').then( m => m.SaleReportOrderPageModule)
  },
  {
    path: 'user-login',
    loadChildren: () => import('./users/user-login/user-login.module').then( m => m.UserLoginPageModule)
  },
  {
    path: 'agenda-fail',
    loadChildren: () => import('./report/agenda-fail/agenda-fail.module').then( m => m.AgendaFailPageModule)
  },
  {
    path: 'customer-report',
    loadChildren: () => import('./report/customer-report/customer-report.module').then( m => m.CustomerReportPageModule)
  },
  {
    path: 'customer-report-type',
    loadChildren: () => import('./report/customer-report-type/customer-report-type.module').then( m => m.CustomerReportTypePageModule)
  },
  {
    path: 'customer-report-bus',
    loadChildren: () => import('./report/customer-report-bus/customer-report-bus.module').then( m => m.CustomerReportBusPageModule)
  },
  {
    path: 'customer-report-org',
    loadChildren: () => import('./report/customer-report-org/customer-report-org.module').then( m => m.CustomerReportOrgPageModule)
  },
  {
    path: 'report-sell',
    loadChildren: () => import('./report/report-sell/report-sell.module').then( m => m.ReportSellPageModule)
  },  
];

@NgModule({
  imports: [
    // RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
    //RouterModule.forRoot(routes)
    RouterModule.forRoot(
      routes,
      { 
        enableTracing: false,
        preloadingStrategy: SelectivePreloadingStrategyService
      }
    )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
