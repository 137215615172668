import { UtilService } from 'src/app/service/util.service';
import { GlobalServiceService } from './service/global-service.service';
import { Component, OnInit } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router } from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  // public selectedIndex = 0;
  // public subIndex = 0;

  public SHOW_SIDEBAR = '0';

  public open = true;
  public open2 = false;

  public user: any;
  // public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
  public count1 = 0;
  public count2 = 0;
  public count3 = 0;

  // private sale_section = [];
  // private product_section= [];
  // private user_section=[];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
    public util: UtilService,
    public global: GlobalServiceService
  ) {
    // console.log('storate');
    // console.log(localStorage);
    // console.log('history');
    // console.log(history.state);
    // console.log('register foo')
    console.log('app page');
    console.log(global.appPages);
    this.user = {};
    // console.log();
    if (history.state != null || history.state !== undefined) {
      console.log('have state');
      console.log(history.state);
      if (history.state) {
        const getuser = history.state.user;
        //console.log("XXX"+ getuser);
        if (getuser) {
          this.util.USER = JSON.parse(getuser.info); //JSON.parse(localStorage.user);
          this.util.PG = JSON.parse(getuser.pg);
        } else {
          if (localStorage.user) {
            this.util.PG = JSON.parse(localStorage.pg);
            this.util.USER = JSON.parse(localStorage.user);
            this.global.MODE_STATE = localStorage.APP_STATE;
          }
        }
      } else {
        if (localStorage.getItem('pg') !== null) {
          console.log('have storate');
          // this.pg=JSON.parse(localStorage.getItem('pg'));
          this.util.PG = JSON.parse(localStorage.pg);
          this.util.USER = JSON.parse(localStorage.user);
          this.global.MODE_STATE = localStorage.APP_STATE;
          // console.log(this.util.PG);
        }
      }
    } else if (localStorage.pg !== null) {
      // console.log('have storate');
      // this.pg=JSON.parse(localStorage.getItem('pg'));
      this.util.PG = JSON.parse(localStorage.pg);
      this.util.USER = JSON.parse(localStorage.user);
      this.global.MODE_STATE = localStorage.APP_STATE;
      // console.log('menu1 ');
      // console.log(this.pg[1]);
    } else {
      console.log('NO DATA');
      this.util.getObservable().subscribe((data) => {
        console.log('Data received', data);
        this.util.PG = data;
        //this.user = this.user;
      });
    }
    console.log(this.util.USER);
    console.log(this.util.PG);
    if (this.util.PG) {
      // this.sale_section = this.util.PG[1];
      // this.product_section = this.util.PG[2];
      // this.user_section = this.util.PG[3];
      if (localStorage.getItem('APP_STATE') === 'SALES') {
        this.global.appPages = this.util.PG[1];
      } else if (localStorage.getItem('APP_STATE') === 'PRODUCTS') {
        this.global.appPages = this.util.PG[2];
      } else if (localStorage.getItem('APP_STATE') === 'USERS') {
        this.global.appPages = this.util.PG[3];
      }
      // this.global.appPages = this.util.PG[1];
      // console.log('PGXXX');
      // console.log(this.global.appPages);
      // console.log('PGXXX-END');

      // if(this.util.PG[1].length === 0) {
      //   this.count1 = 0;
      // }else{
      //   this.count1 = 1;
      // }
      // if(this.util.PG[2].length === 0) {
      //   this.count2 = 0;
      // }else{
      //   this.count2 = 1;
      // }
      // if(this.util.PG[3].length === 0) {
      //   this.count3 = 0;
      // }else{
      //   this.count3 = 1;
      // }
      // console.log('user component');
      // console.log(this.user);
    }

    if (localStorage.getItem('selectedIndex') !== null) {
      this.util.selectedIndex = parseInt(localStorage.getItem('selectedIndex'));
    }

    if (localStorage.getItem('subIndex') !== null) {
      this.util.subIndex = parseInt(localStorage.getItem('subIndex'));
    }
    // console.log(this.user);
    // console.log(this.sale_section);
    this.initializeApp();
  }

  initializeApp() {

    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      // this.splashScreen.hide();
      console.log(this.platform.platforms());
      this.util.CURR_DEVICE = this.platform.platforms()[0];
      console.log(this.util.CURR_DEVICE);
    });

    // this.SHOW_SIDEBAR = localStorage.getItem('SWB');
    // const path = window.location.pathname.split('/')[1];
    // console.log(path);
    // if (path === 'login') {
    //   this.SHOW_SIDEBAR = false;
    // } else if ( path === 'main-control') {
    //   this.SHOW_SIDEBAR = false;
    // } else {
    //   this.SHOW_SIDEBAR = true;
    // }
    this.global.lookupMasterStatus().subscribe((data: any) => {
      // console.log(data);
      this.util.MASTER_STATUS_LIST = data;
    });
  }

  ionViewDidEnter() {
    //console.log("Render");
    // console.log(this.global.MODE_STATE);
  }

  ngOnInit() {
    // const path = window.location.pathname.split('/')[1];
    // // console.log(path);
    // if (path === 'login') {
    //   this.SHOW_SIDEBAR = false;
    // } else if ( path === 'main-control') {
    //   this.SHOW_SIDEBAR = false;
    // } else {
    //   this.SHOW_SIDEBAR = true;
    // }
    // if (path !== undefined) {
    //   this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    // }
    // console.log('STATE: ' + this.global.MODE_STATE);
    // if (!this.global.MODE_STATE) {
    //   this.router.navigate(['main-control']);
    // }
  }

  gotoCenter() {
    this.router.navigate(['main-control']);
  }

  subSelect(index, p) {
    console.log(index);
    console.log('p');
    console.log(p);
    this.util.subIndex = index;
    this.util.selectedIndex = p;
    localStorage.setItem('selectedIndex', p);
    localStorage.setItem('subIndex', index);
    this.util.start_selectedIndex =-1;
    this.util.start_subIndex = -1;
    console.log(this.util);
  }

  menuSelect(index) {
    console.log('index');
    console.log(index);
    
    this.util.selectedIndex = index;
    localStorage.setItem('selectedIndex', index);
    //localStorage.setItem('subIndex', parseInt('-1'));
    this.util.subIndex = -1;
    this.util.start_selectedIndex =-1;
    this.util.start_subIndex = -1;
    this.open = false;
    console.log(this.util);
  }
  
  logOut() {
    // localStorage.clear();
    localStorage.clear();
    this.router.navigate(['login']);
  }

  subSelect2(i){
    if(this.util.selectedIndex==i){
      this.open=true;
      return true;
    }
    else{
      this.open=false
      return false;
    }
  }
}
