import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';
// import { readFile } from 'fs';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  API_URL = 'https://sanyapi.cbxcode.com/';
  //API_URL = 'http://localhost:8080/sany_api/';
  // API_URL = 'http://192.168.100.159/';
  API_UPLOAD = 'https://sanyapi.cbxcode.com/products/upload';
  // API_UPLOAD = 'http://192.168.100.159/products/upload';
  USER_DATA: any;
  USER: any;
  PG: any;

  CURR_DEVICE: any; // iphone, android ,ipad ,desktop

  selectedIndex = 0;
  subIndex = 0;
  start_selectedIndex = 0;
  start_subIndex = 0;
  MASTER_STATUS_LIST: any;

  private fooSubject = new Subject<any>();

  publishSomeData(data: any) {
        this.fooSubject.next(data);
  }

  getObservable(): Subject<any> {
        return this.fooSubject;
  }

  constructor(
    private http: HttpClient
  ) { }

  fontSarabanNew() {
    return this.http.get('../../assets/fonts/sarabannew.json');
    // this.http.get('../../assets/fonts/saraban');
  }
}
