import { UtilService } from 'src/app/service/util.service';
import { Injectable } from '@angular/core';
// import { JwtHelperService } from '@auth0/angular-jwt';
import decode from 'jwt-decode';
import { GlobalServiceService } from '../global-service.service';
@Injectable()
export class AuthService {
  constructor(
      // public jwtHelper: JwtHelperService
      public util: UtilService,
      public global : GlobalServiceService
) {}
  // ...
  public isAuthenticated(): boolean {
    const token = localStorage.token;
    const user = localStorage.user;
    if (!token) {
      console.log('token not found..');
      return false;
    }
    this.util.USER = JSON.parse(user);
    this.util.PG = JSON.parse(localStorage.pg);
    this.global.MODE_STATE = localStorage.APP_STATE;
    this.global.appPages = this.util.PG[localStorage.mIndex];
    // Check whether the token is expired and return
    // true or false
    // return !this.jwtHelper.isTokenExpired(token);
    // if (!user) {
    //     return false;
    // }


    const CUR_TIME = Number(Date.now().toString().substr(0, 10));
    const TOKEN =  decode(token);
    let DIFF =  CUR_TIME - TOKEN.API_TIME;
    // console.log((DIFF / 3600000) );
    // console.log(decode(token));
    // if ((DIFF / 3600000) > 0) {
    //    return false;
    // }
    // console.log(CUR_TIME + " - " + TOKEN.API_TIME);
    return true;
  }
}