import { Injectable } from '@angular/core';
import 'rxjs/add/observable/of';
import { PreloadingStrategy, Route } from '@angular/router';
// import { Observable } from 'rxjs/Observable';
import { Observable } from 'rxjs/Observable';
 
@Injectable()
export class SelectivePreloadingStrategyService {
  public preloadedModules: string[] = [];
  
  constructor() { }
 
  preload(route: Route, load: () => Observable<any>): Observable<any> {
    // เช็ค route ที่มีการกำหนด data และ data preload
    if (route.data && route.data['preload']) {
      // เพื่อ route path ที่จะใช้งาน  ไว้ใน preloaded module array
      this.preloadedModules.push(route.path);
 
      // แสดง route path ใน console
      console.log('Preloaded: ' + route.path);
 
      return load(); // โหลด module นั้น
    } else {
      return Observable.of(null); // คืนค่า null
      //return null;
    }
  }
 
}